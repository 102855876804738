$primary-color: #00095B !default 
$text-color: #fff !default
$dark-text-color: #4D4D4D !default 
$muted-text: #6E6E6E !default 
$bg-color: #F4F4F4 !default
$bg-light: #f8f9fa !default
$light-gray-color: #F2F2F2 !default
$light-color: #fff !default
$dark-color: #000 !default
$red-color: #D62D0A !default
$green-color: #008200 !default 
$blue-bootstrap-color: #0d6efd !default
$warning-bootstrap-color: #BA4E00 !default 
$danger-bootstrap-color: #FF3030 !default 
$dark-bootstrap-color: #212529 !default
$font-bold: bold !default
$font-bolder: bolder !default
//FordF1 Font family
$light-font-family: FordF1-Light !default
$medium-font-family: FordF1-Medium !default
$regular-font-family: FordF1-Regular !default
$semibold-font-family: FordF1-Semibold !default
$bold-font-family: FordF1-Bold !default
$position-fixed: fixed !default
$max-z-index: 999 !default
$border-radius: 10px !default
//MEDIA QUERY RESOLUTION
$min-screen-resolution-width: 320px !default
$max-screen-resolution-width: 991px !default

//PRODUCT COUNTER
$product-counter-background-color: $light-color !default
$product-counter-border-color: 1px solid $light-gray-color !default
$custom-icons-path: '../../node_modules/srs.sharedcomponents/lib/esm/sass/custom-icons'
$custom-display: none !default