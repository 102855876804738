@import '_constants.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_fonts.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_mixins.sass'
@import '_variables.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/components/__components.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/layout/__layout.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/pages/__pages.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/main.sass'
@import '_fonts.sass'
@import '_breadcrumb.sass'

#superseed-label
    width: 100px

.default-cart-counter
    &:hover
        color: $dark-color
.header__row
    .col-xl-6
        width: 100% !important
.card-info a.supersedes-link
    color: rgb(108, 117, 125) !important
    text-decoration: none !important
[class^="srs-icon-"], [class*=" srs-icon-"], .path1.font-xl, .path2.font-xl
    font-family: 'srs-font-icons', sans-serif !important
    font-style: normal
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

.sub-cart > :nth-last-child(2)
    margin-bottom: 0 !important

.key-finder-dropdown, [id^="option_facetname_"]
    display: flex !important
    justify-content: flex-start !important

#search, #part-number
    width: 100% !important
    overflow: hidden !important
    text-overflow: ellipsis !important
    white-space: nowrap !important

#search::placeholder, #part-number::placeholder
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    width: 80%

.chip-button .MuiButton-startIcon
    display: inline-block

.keyfinder-item-details
    display: flex
    justifyContent: space-between

.product-item-image-container
    border-style: none !important

#keyFinderResetBtn
    background-color: transparent

.multiSelect-Class
    padding-left: 20px

.rotunda-logo
    width: 25%

.header-product-group
    width: auto !important

.header-outer-search
    display: none !important
    padding-bottom: 0
    margin-top: 1.5rem
    width: 100%

@media (max-width: 1500px)
    .product-item-main-content
        flex-direction: row !important

@media (min-width: 992px) and (max-width: 1400px)
  .header-outer-search
    display: flex !important

  .header-inner-search
    display: none !important

  .header-group
    gap: 30px !important
    justify-content: unset !important
    padding-top: 0 !important

@media (min-width: 768px) and (max-width: 991px)
  .logo-link
    width: 60%
    margin-left: 10%
    margin-right: 10%
  #headerLogoImg
    width: 50% !important
  .header-select-form
    width: 80%
    margin: auto !important

@media (max-width: 991px)
    .multiSelect-Class
        overflow-y: auto
        max-height: 400px 

@media (max-width: 767px)
    .logo-link
        width: 75%
        margin-left: 10%
        margin-right: 10%
    .header-select-form
        width: 95%
        margin: auto !important
    #headerLogoImg
        width: 50% !important
    .keyfinder-item-details
        display: block !important
.checkout-redirect a
    color: rgb(14, 22, 100) !important
    text-align: center
