.breadcrumb
    padding: 0.2rem 0
    margin-bottom: 0rem

    &-item + .breadcrumb-item::before
        content: ">" !important

nav.breadcrumb
    padding: 10px 20px 0 20px !important

.bread-and-message
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

.base-margin
    padding: 0px 20px !important

@media (max-width: 768px)
    .bread-and-message
        display: contents
    .breadcrumb
        flex-direction: revert

    