@font-face
 font-family: "FordF1-Bold"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/FordF1-Bold.otf") format("opentype")

@font-face
 font-family: "FordF1-Semibold"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/FordF1-Semibold.otf") format("opentype")

@font-face
 font-family: "FordF1-Medium"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/FordF1-Medium.otf") format("opentype")

@font-face
 font-family: "FordF1-Regular"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/FordF1-Regular.otf") format("opentype")

@font-face
 font-family: "FordF1-Light"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/FordF1-Light.otf") format("opentype")

@font-face
 font-family: "ford-antenna-black-cmp"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-black-cmp-587eb6f0d9e46.otf") format("opentype")

@font-face
 font-family: "ford-antenna-bold-italic-cmp"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-bold-italic-cmp-587bd989c0b2d.otf") format("opentype")

@font-face
 font-family: "ford-antenna-bold-italic-cnd"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-bold-italic-cnd-58894aad940e0.otf") format("opentype")

@font-face
 font-family: "ford-antenna-italic"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-italic-588ce13ac85a5.otf") format("opentype")

@font-face
 font-family: "ford-antenna-medium"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-medium-58955836e60d2.otf") format("opentype")

@font-face
 font-family: "ford-antenna-medium-cnd"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-medium-cnd-587bd97171cbd.otf") format("opentype")

@font-face
 font-family: "ford-antenna-medium-italic-cmp"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-medium-italic-cmp-588e52bbb4e9f.otf") format("opentype")

@font-face
 font-family: "ford-antenna-regular-xcnd"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-regular-xcnd-589558121012a.otf") format("opentype")

@font-face
 font-family: "ford-antenna-semibold-italic"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-semibold-italic-588ce16abbe89.otf") format("opentype")

@font-face
 font-family: "ford-antenna-thin-italic-xcnd"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/ford-antenna-thin-italic-xcnd-58894adb8be4f.otf") format("opentype")

@font-face
 font-family: "SourceSansPro-Black"
 font-weight: normal
 font-style: normal
 src: url("../../public/fonts/SourceSansPro-Black.otf") format("opentype")

.header__top-nav
    font-family: $regular-font-family

.header__menu
    font-family: $regular-font-family

.bread-and-message
    font-family: $medium-font-family

.item__details
    font-family: $regular-font-family

.order-history
    font-family: $regular-font-family

.wish-list
    font-family: $regular-font-family

.quick-order
    font-family: $regular-font-family

.my-preferences
    font-family: $regular-font-family

.contact-us
    font-family: $regular-font-family

.user__registration
    font-family: $regular-font-family

.login-comp
    font-family: $regular-font-family

.cart-fonts
    font-family: $regular-font-family

.left-menu
    font-family: $regular-font-family

.product-listing
    font-family: $regular-font-family

.check-out
    font-family: $regular-font-family

.WebpartParamSelector
    font-family: $regular-font-family

body
    font-family: $regular-font-family

.builder-block
    font-family: $regular-font-family

.multiSelect-Class div, .multiSelect-Class button,.multiSelect-Class p
    font-family: $regular-font-family
